<template>
  <div class="mt-2">
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item">
        <router-link
          class="nav-link"
          :class="{ active: tab == 1 }"
          data-toggle="tab"
          :to="{ query: { tab: 1 } }"
        >
          <i class="fa fa-check-circle"></i> Prestaciones
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          :class="{ active: tab == 2 }"
          data-toggle="tab"
          :to="{ query: { tab: 2 } }"
        >
          <span class="fa-stack" style="height:1em;width:1em;line-height:1em">
            <i class="fa fa-stack-1x fa-file-o"></i>
            <i class="fa fa-stack-1x fa-usd" style="font-size:0.6em"></i>
            <i
              class="fa fa-stack-1x fa-check"
              style="margin:0.6em;font-size:0.6em"
            ></i>
          </span>
          Pagos realizados
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          :class="{ active: tab == 3 }"
          data-toggle="tab"
          :to="{ query: { tab: 3 } }"
        >
          <span class="fa-stack" style="height:1em;width:1em;line-height:1em">
            <i class="fa fa-stack-1x fa-file-o"></i>
            <i class="fa fa-stack-1x fa-usd" style="font-size:0.6em"></i>
            <i
              class="fa fa-stack-1x fa-check"
              style="margin:0.6em;font-size:0.6em"
            ></i>
          </span>
          Por pagar
        </router-link>
      </li>
    </ul>
    <div class="mb-3">
      <h4>Sobre este Laboratorio</h4>
      <hr />
      <table>
        <tr>
          <th class="text-muted pb-1">Nombre</th>
          <td>{{ supplier.name }}</td>
        </tr>
        <tr>
          <th class="text-muted pb-1 pr-5">Dirección</th>
          <td>{{ supplier.address }}</td>
        </tr>
        <tr>
          <th class="text-muted pb-1">Teléfono</th>
          <td>{{ supplier.phone }}</td>
        </tr>
        <tr>
          <th class="text-muted pb-1">Detalle</th>
          <td>{{ supplier.observation }}</td>
        </tr>
      </table>
    </div>
    <div class="tab-content">
      <div class="tab-pane fade" :class="{ 'show active': tab == 1 }">
        <prices :supplier_id="supplier_id"></prices>
      </div>
      <div class="tab-pane fade" :class="{ 'show active': tab == 2 }">
        <payments ref="elPayments" :supplier_id="supplier_id"></payments>
      </div>
      <div class="tab-pane fade" :class="{ 'show active': tab == 3 }">
        <to-pay
          :supplier_id="supplier_id"
          @paid="$refs.elPayments.reload()"
        ></to-pay>
      </div>
    </div>
  </div>
</template>
<script>
import { LogisticService } from "../../logistic-module/service";
import prices from "./prices";
import toPay from "./to-pay";

import payments from "./payments.vue";

export default {
  components: {
    prices,
    toPay,
    payments
  },

  // directives
  // filters

  props: {
    supplier_id: { required: true }
  },

  data: () => ({
    supplier: {}
  }),

  computed: {
    tab() {
      return this.$route.query.tab || 1;
    }
  },

  watch: {
    supplier_id: {
      immediate: true,
      handler() {
        this.getValue();
      }
    }
  },

  mounted() {
    //
  },

  methods: {
    getValue() {
      LogisticService.getSupplier(this.supplier_id).then(reg => {
        this.supplier = reg;
      });
    }
  }
};
</script>

<style></style>
